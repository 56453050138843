import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Welcome</h1>
    <p>Hello, this is my page where I talk about amateur radio.  I got my Basic license in 2005.  I got my Advanced license in 2019.  I am currently working on the 5WPM Morse Code certification.</p>

    <p>I mainly got into amateur radio because friends had their license and used amateur radio to talk on road trips and camping.  I wanted to be able to communicate with them as well.  When I moved the Sunshine Coast, I got more into the hobby and got involved with the Sun Coast Amateur Radio Club Society.</p>

    <p>I operate a repeater at my house - <a href="https://ve7qnx.ca">VE7QNX</a></p>
  </Layout>
)

export default IndexPage
